<template>
  <div>
    <b-form-rating
      v-model="value"
      show-clear
      show-value
      inline
      variant="warning"
    />
  </div>
</template>

<script>
import { BFormRating, BCardText } from 'bootstrap-vue'
import { codeClearButton } from './code'

export default {
  components: {
    BFormRating,
    BCardText,
  },
  data() {
    return {
      value: 2.5,
      codeClearButton,
    }
  },
}
</script>
